import React from 'react';
import { SimpleGrid, Skeleton, Stack } from '@chakra-ui/react';
import { NextSeo } from 'next-seo';

import { initializeApollo } from '../lib/auth/apollo';
import { getNav } from '../lib/get-nav';
import { PageLayout } from '../components/page-layout';
import gql from 'graphql-tag';
import { LIST_PRODUCT_FRAGMENT } from './products';
import { useQuery } from '@apollo/client';
import { Carousel } from '../components/carousel';
import { useAuth } from '../providers/auth';
import { LandingPage } from '../components/landing-page/LandingPage';
import { Container } from '@outdoorly/container';
import { Cluster } from '@outdoorly/cluster';
import { Divider } from '@outdoorly/divider';

export const FEATURE_FRAGMENT = gql`
  fragment featureFragment on Feature {
    type
    title
    headlineText
    subheaderText
    descriptionText
    descriptionTitle
    backgroundImage {
      image {
        url
      }
    }
    mobileImage {
      id
      image {
        url
      }
    }
    smallLogo {
      fileUrl
    }
    largeLogo {
      fileUrl
    }
    linkedObject {
      id
      text
      brand {
        slug
      }
      category {
        slug
      }
      collection {
        slug
      }
      page {
        slug
      }
      url
    }
  }
`;

const HOMEPAGE_QUERY = gql`
  query HomepageQuery {
    shop {
      homepage {
        id
        homepageCarousels(first: 10) {
          edges {
            node {
              id
              position
              carousel {
                id
                name
                description
                elementType
                paginate
                featureCarousels(first: 10) {
                  edges {
                    node {
                      feature {
                        ...featureFragment
                      }
                    }
                  }
                }
                products(first: 30, onlyVisibleOnStorefront: true) {
                  edges {
                    node {
                      ...productFragment
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${FEATURE_FRAGMENT}
  ${LIST_PRODUCT_FRAGMENT}
`;

export async function getStaticProps() {
  const apolloClient = initializeApollo();

  await getNav(apolloClient);

  return {
    props: {
      initialApolloState: apolloClient.cache.extract(),
    },
    revalidate: 1000,
  };
}

const HomePage = () => {
  const { isAuthed, isLoading } = useAuth();

  const { data, loading } = useQuery(HOMEPAGE_QUERY, {
    skip: !isAuthed,
  });
  if (!isAuthed && !isLoading) return <LandingPage />;
  else if (isAuthed) {
    if (loading) {
      return (
        <PageLayout authWall={false}>
          <NextSeo
            title="Outdoorly"
            description="Rewarding outdoor experts with exclusive perks and discounts"
            openGraph={{
              url: `https://www.outdoorly.com`,
              title: 'Outdoorly',
              description:
                'Rewarding outdoor experts with exclusive perks and discounts',
            }}
          />
          <Stack spacing={10} overflow="hidden" flex={1}>
            <Skeleton width="full" height="xl" />
            <Container>
              <SimpleGrid columns={[1, null, 2]} gap={6}>
                <Skeleton borderRadius="xl" width="full" height="sm" />
                <Skeleton borderRadius="xl" width="full" height="sm" />
              </SimpleGrid>
            </Container>
            <Container>
              <Divider />
            </Container>
            <Skeleton
              borderRadius="md"
              height={10}
              width="lg"
              alignSelf="center"
            />
            <Container>
              <SimpleGrid columns={[1, null, 2]} gap={6}>
                <Skeleton borderRadius="xl" width="full" height="sm" />
                <Skeleton borderRadius="xl" width="full" height="sm" />
                <Skeleton borderRadius="xl" width="full" height="sm" />
                <Skeleton borderRadius="xl" width="full" height="sm" />
              </SimpleGrid>
            </Container>
            <Container>
              <SimpleGrid columns={[1, null, 3]} gap={6}>
                <Skeleton borderRadius="xl" width="full" height="2xs" />
                <Skeleton borderRadius="xl" width="full" height="2xs" />
                <Skeleton borderRadius="xl" width="full" height="2xs" />
              </SimpleGrid>
            </Container>
            <Skeleton
              borderRadius="md"
              height={10}
              width="lg"
              alignSelf="center"
            />
            <Container>
              <Cluster justifyContent="center">
                <Skeleton borderRadius="xl" width="180px" height="180px" />
                <Skeleton borderRadius="xl" width="180px" height="180px" />
                <Skeleton borderRadius="xl" width="180px" height="180px" />
                <Skeleton borderRadius="xl" width="180px" height="180px" />
                <Skeleton borderRadius="xl" width="180px" height="180px" />
                <Skeleton borderRadius="xl" width="180px" height="180px" />
              </Cluster>
            </Container>
          </Stack>
        </PageLayout>
      );
    }

    return (
      <PageLayout authWall={false}>
        <NextSeo
          title="Outdoorly"
          description="Rewarding outdoor experts with exclusive perks and discounts"
          openGraph={{
            url: `https://www.outdoorly.com`,
            title: 'Outdoorly',
            description:
              'Rewarding outdoor experts with exclusive perks and discounts',
          }}
        />
        <Stack spacing={10} overflow="hidden">
          {data?.shop.homepage.homepageCarousels.edges[0] && (
            <Carousel
              key={data?.shop.homepage.homepageCarousels.edges[0].node.id}
              carousel={
                data?.shop.homepage.homepageCarousels.edges[0].node.carousel
              }
            />
          )}
          {data?.shop.homepage.homepageCarousels.edges
            .slice(1)
            .map((edge, index, array) => (
              <>
                <Carousel carousel={edge.node.carousel} key={edge.node.id} />
                {index !== array.length - 1 && (
                  <Container>
                    <Divider />
                  </Container>
                )}
              </>
            ))}
        </Stack>
      </PageLayout>
    );
  }

  return null;
};

export default HomePage;
